<template>
    <main>
        <div class="hero" style="background-image: linear-gradient(rgba(0 0 0 / 0.3), rgba(0 0 0 / 0.3)), url('images/cb_inner_pages_default_bg-min.jpg');">
           <div class="container container--xs text-center">
                <h1>Mensaje principal de imagen de promoción</h1>
                <p>Subtítulo sumilla o texto editable</p>
           </div>
        </div>
        <section class="section pt-0">
            <div class="container">
                <p class="breadcrumb"><a href="/"><span class="icon-home-outline"></span></a> / <span>Políticas de privacidad</span></p>
                <h2><a href="#" @click="router.go(-1)"><img src="images/arrowleft.svg" alt="Club de Sastreria Barrington"></a> Políticas de privacidad</h2>
                <div class="grid grid--one-columns grid--gap-md py-lg pt-0">
                    <p>El Programa es administrado por Aris Industrial S.A (en adelante, "BARRINGTON"), empresa que puede a su única discreción, designar ciertas tareas relacionadas con la administración del Programa a cualquiera de sus filiales o subsidiarias designadas. </p>

<p>Los presentes términos y condiciones detallan las condiciones de la relación entre BARRINGTON y los socios del Programa (quienes serán denominados indistintamente "socio(s)"), incluida la forma en que los socios obtienen puntos, gestionan sus cuentas, logran tener cierto nivel de membresía, canjean puntos del Programa, entre otros. Se rigen por las leyes de la República del Perú y deben interpretarse en virtud de estas.
Al inscribirse y abrir una cuenta de membresía del Programa, o al usar su tarjeta virtual o el número de Cuenta que le fue asignado, usted manifiesta, a su vez, (i) que ha leído y acepta los presentes T&C y, (ii) que otorga su consentimiento para la recopilación, uso y divulgación de sus datos personales por parte de BARRINGTON y el Programa, de acuerdo con las disposiciones establecidas en el presente documento y en nuestra Política de Protección de Datos Personales.</p>
<p>Todos los beneficios y premios están sujetos a disponibilidad y BARRINGTON puede cambiarlos sin previo aviso. El Programa no posee una fecha predeterminada de finalización y puede continuar hasta que BARRINGTON decida finalizarlo total o parcialmente, en cuyo caso avisará a los Socios con una anticipación mínima de dos (02) meses. Los Socios dispondrán de dicho plazo para acumular puntos, utilizar los beneficios y canjear los puntos acumulados. Luego de ello, los Puntos sin canjear serán cancelados sin ninguna obligación ni responsabilidad y no se respetarán las solicitudes de Canje realizadas con posterioridad.
BARRINGTON se reserva el derecho de añadir, limitar, modificar, corregir o eliminar cualquiera de los procedimientos, reglas, condiciones, beneficios, niveles de membresía, o cualquier otro aspecto de los T&C, en cualquier momento, con o sin previo aviso, aun cuando dichos cambios pueden afectar al valor de puntos, o la capacidad de obtener ciertos premios o beneficios. Los Socios del Programa son responsables de conocer en todo momento los T&C, cualquier política relacionada y cualquier cambio de estas. Su participación continua en el Programa de Lealtad constituirá su aceptación de dichas modificaciones.
</p>
                </div>
                
            </div>
        </section>
    </main>
</template>


<script>
import { ref, computed } from "vue";
import router from "@/router"
import API from "@/api";

export default {
    name: 'PrivacyPolicies',
    components: {
        
    },

    setup(props, context) {
        return { router }
    }

    
}
</script>